import { Avatar } from '@mui/material';
import placeholderImage from 'assets/images/cerego-icon-grey-rounded.png';
import { MemreButton, MemreProgressBar, MemreTable } from 'components/core';

import useAssignmentsV3 from 'hooks/useAssignmentsV3';

import * as Color from 'core/colors';
import { TableColumnDef } from 'models/table';
import moment from 'moment/moment';
import { getLearnSetUrl } from 'utils/pathUtils';

const buttonText = (assignment) => {
  if (!assignment.meta.memoryAggregate.seeNextAt) {
    return 'Learn';
  } else if (moment(assignment.meta.memoryAggregate.seeNextAt).isBefore(moment())) {
    return 'Review Now';
  }
  return `Review At ${moment(assignment.meta.memoryAggregate.seeNextAt).fromNow()}`;
};

export default function CourseItemsTable({ assignmentCount, courseId }) {
  const columns: Array<TableColumnDef<any>> = [
    {
      id: 'Image',
      accessorFn: (row: any) => row.name,
      cell: (row: any) => (
        <Avatar alt={row.row.original.name} src={row.row.original.image?.smallUrl || placeholderImage} />
      ),
      header: () => '',
    },
    {
      id: 'Assignment Name',
      accessorFn: (row: any) => row.name,
      sortable: 'name',
      searchable: true,
    },
    {
      id: 'Items',
      accessorFn: (row: any) => row.itemsCount,
      rightAlign: true,
    },
    {
      id: 'Started',
      accessorFn: (row: any) => row.meta.memoryAggregate.studiedItemsCount,
      rightAlign: true,
    },
    {
      id: 'To Review',
      accessorFn: (row: any) => row.meta.memoryAggregate.eligibleItemsCount,
      cell: (row: any) =>
        row.getValue() > 0 ? <b style={{ color: Color.reportRed }}>{row.getValue()}</b> : row.getValue(),
      rightAlign: true,
    },
    {
      id: 'Progress',
      accessorFn: (row: any) => row.meta.memoryAggregate.progress * 100,
      cell: (row: any) => <MemreProgressBar minWidth="40px" progress={row.getValue()} />,
      fillWidth: true,
    },
    {
      id: 'CTA',
      accessorFn: (row: any) => ({
        text: buttonText(row),
        study: () => (window.location.href = getLearnSetUrl(row.learnVersion, row.id)),
      }),
      cell: (row: any) => (
        <MemreButton
          text={row.getValue().text}
          variant="outlined"
          onClick={row.getValue().study}
          color={row.getValue().text === 'Review' ? 'secondary' : 'primary'}
          iconName="arrowFromBracket"
          size="small"
        />
      ),
      rightAlign: true,
      header: () => '',
    },
  ];

  return <MemreTable useData={useAssignmentsV3} columns={columns} id={courseId} totalCount={assignmentCount} />;
}
